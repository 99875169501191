// import logo from "./logo.svg";
import React from "react";
import "./App.css";
import { addProduct, addSubscription } from "./Backend";
// import FrameComponent from "./FrameComponent";
function getWindowSize() {
	const { innerWidth, innerHeight } = window;
	return { innerWidth, innerHeight };
}
function App() {
	const [windowSize, setWindowSize] = React.useState(getWindowSize());
	const [name, setName] = React.useState("");
	const [friendOne, setfriendOne] = React.useState("");
	const [friendTwo, setfriendTwo] = React.useState("");
	const [friendThree, setfriendThree] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [instagram, setInstagram] = React.useState("");
	const [phoneNumber, setPhonenumber] = React.useState("");
	const [subsrciptionEmail, setSubscriptionEmail] = React.useState("");
	React.useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize());
		}

		window.addEventListener("resize", handleWindowResize);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, []);
	const CheckAndSubmit = async () => {
		if (!name || !email || !instagram) {
			alert("please fill all fields");
		} else {
			const rsponse = await addProduct({
				name,
				friendOne,
				friendTwo,
				friendThree,
				email,
				phoneNumber,
				instagram,
			});
			if (rsponse) {
				alert(
					"We are happy to hear that you want to join the community\n Your details have been sent."
				);

				setEmail("");
				setName("");
				setInstagram("");
				setfriendOne("");
				setfriendTwo("");
				setPhonenumber("");
				setfriendThree("");
			} else {
				alert("Please try again later");
			}
		}
	};
	const checkAndSubscribe = async () => {
		if (!subsrciptionEmail) {
			alert("Please enter your email first!");
		} else {
			const res = await addSubscription(subsrciptionEmail);
			if (res) {
				alert("Thankyou for subscribing to our Newsletter");
				setSubscriptionEmail("");
			} else {
				alert("Please try again later!");
			}
		}
	};
	return (
		<div
		// style={{
		// 	display: "flex",
		// 	width: "100vw",
		// 	height: "100%",
		// 	flexDirection: "column",
		// }}
		>
			<div
				className="specialFrame"
				style={{
					backgroundImage:
						windowSize.innerWidth > 400
							? "url(/ezgif.com-webp-to-jpg.jpg)"
							: "url(/ikaur.png)",
					minHeight: windowSize.innerWidth > 400 ? "200vh" : "120vh",
					// background: "#E4DED5",
				}}
			>
				{/* <img
					src="/logo.jpeg"
					style={{ width: "100%", height: "100%", objectFit: "contain" }}
				/> */}
				{/*	<image src="/topImg.png" className="topImg" /> */}
				{/* {windowSize.innerWidth > 400 && ( */}
				{/* // <span className="topHeading">Modelofthemonth.org</span> */}
				<img src="/ittlee.png" className="imgTop" />
				{/* )} */}
				{/* <div
					className="colCenter"
					style={{ height: "100vh", width: "100vw", paddingTop: 160 }}
				>
					<div className="colCenter">
						<span className="swimissuepr">Swimissue presents</span>
						<span className="titleWeb">Modelofthemonth.org</span>
						<div className="rowing btnsFrame">
							<span className="activeBtn activeBtnText">Join Community</span>
							<span className="inActiveBtn inActiveBtnText">Donate</span>
							<span className="inActiveBtn inActiveBtnText">Play video</span>
						</div>
					</div>
				</div> */}
				<div className="centerMe">
					{/* <img
						src="/RectangleVid.png"
						style={{ width: 543, height: 300, marginTop: 390 }}
					/> */}

					{
						<video
							// ref={}
							style={{
								width:
									windowSize.innerWidth > 400 ? 543 : windowSize.innerWidth,
								height: windowSize.innerWidth > 400 ? 300 : 200,
								marginTop: windowSize.innerWidth > 400 ? -150 : -10,
								objectFit: "cover",
								// borderRadius: windowSize.innerWidth > 400 ? 32 : 8,
							}}
							src={"/viddd.mp4"}
							poster="/ltsttPoster.png"
							onClick={(e) => {
								e.currentTarget.play();
								e.currentTarget.currentTime === 0 || e.currentTarget.paused
									? e.currentTarget.play()
									: e.currentTarget.pause();
							}}
							controls
						></video>
					}

					{/* {windowSize.innerWidth <= 400 && (
						<iframe
							title="vimeo-player"
							src="https://player.vimeo.com/video/832919803?h=9ce7c73db3"
							placeholder="/ltsttPoster.png"
							width={640}
							height={360}
							frameborder="0"
							allowfullscreen
							style={{ marginTop: 330 }}
						></iframe>
					)} */}
				</div>
				<div
					className={windowSize.innerWidth > 400 ? "rowing" : "colCenter"}
					style={{
						padding:
							windowSize.innerWidth > 400
								? "200px 80px 0px 80px"
								: "100px 24px 0px 24px",
					}}
				>
					<div
						className="colStart" //style={{ padding: "80px 80px 56px 80px" }}
					>
						<span
							className="podcasTitle"
							style={{
								width: windowSize.innerWidth > 400 ? 800 : 345,
								fontSize: windowSize.innerWidth > 400 ? 56 : 32,
								lineHeight: windowSize.innerWidth > 400 ? "67px" : "38px",
							}}
						>
							PODCAST SERIES DRIVING CHARITY DONATIONS
						</span>
						<span
							className="descriptionPodcast"
							style={{
								width: windowSize.innerWidth > 400 ? 628 : 345,
								marginTop: windowSize.innerWidth > 400 ? 48 : 16,
								fontSize: windowSize.innerWidth > 400 ? 18 : 16,
								lineHeight: windowSize.innerWidth > 400 ? "32px" : "24px",
							}}
						>
							The Model of the Month Podcast series will have a{" "}
							<b>
								<u>direct website link to a charity</u>
							</b>{" "}
							or Gofundme page supporting the models favorite charity. As the
							podcast episodes gain views across YouTube, TikTok, Instagram
							reels, Snapchat & social media, the online donations to the
							model’s charity from their fans & followers will also increase.
						</span>
					</div>
					{/* <div style={{ padding: "0px 0px 80px 80px" }}> */}
					{windowSize.innerWidth > 400 && (
						<img
							src="/arr.png"
							style={{
								height: 320, //windowSize.innerWidth > 400 ? 320 : 100,
								width: 260, //windowSize.innerWidth > 400 ? 260 : 200,
								paddingLeft: 0,
								marginTop: 0,
								objectFit: "contain",
							}}
						/>
					)}
				</div>
				<div
					style={{
						padding:
							windowSize.innerWidth > 400
								? "40px 80px 40px 80px"
								: "0px 24px 10px 24px",
					}}
				>
					{windowSize.innerWidth > 400 && (
						<img
							src="/xz.png"
							alt="podcast"
							style={{ width: "100%", height: 500, borderRadius: 6 }}
						/>
					)}
					{windowSize.innerWidth <= 400 && (
						<img
							src="/grrppp.png"
							alt="podcast"
							style={{
								width: "100%",
								height: 450,
								objectFit: "contain",
								borderRadius: 6,
							}}
						/>
					)}{" "}
					{/* <span className="whiteBox" style={{ top: -200 }}>
						Gofundme link or donation web link to Model’s Favorite Charity here
					</span> */}
				</div>
			</div>
			{/* <div
				style={{ width: "100%", height: 150, backgroundColor: "#f3f0ed" }}
			></div> */}
			<div className="podcastDiv">
				<div
					className="rowing"
					style={{
						paddingLeft: windowSize.innerWidth > 400 ? 84 : 24,
						paddingTop: windowSize.innerWidth > 400 ? 120 : 100,
						paddingRight: windowSize.innerWidth > 400 ? 84 : 24,
					}}
				>
					<div
						style={{
							width: "100%",
							maxWidth: windowSize.innerWidth > 400 ? 900 : 345,
						}}
					>
						<span
							className="joiningTitle"
							style={{
								fontSize: windowSize.innerWidth > 400 ? 56 : 32,
								lineHeight: windowSize.innerWidth > 400 ? "67px" : "38px",
							}}
						>
							Join the Model of the Month community
						</span>
						<div
							className={windowSize.innerWidth > 400 ? "rowing" : "colCenter"}
							style={{
								marginBottom: windowSize.innerWidth > 400 ? 24 : 16,
								marginTop: windowSize.innerWidth > 400 ? 32 : 40,
							}}
						>
							<input
								className="inputStyling"
								placeholder="Name"
								style={{
									marginBottom: windowSize.innerWidth > 400 ? 0 : 16,
									width: windowSize.innerWidth > 400 ? 410 : 320,
								}}
								onChange={(e) => {
									setName(e.target.value);
								}}
								value={name}
							/>
							<input
								className="inputStyling"
								placeholder="Email"
								style={{
									width: windowSize.innerWidth > 400 ? 410 : 320,
									marginBottom: windowSize.innerWidth > 400 ? 0 : 0,
								}}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
								value={email}
							/>
						</div>

						<div
							className={windowSize.innerWidth > 400 ? "rowing" : "colCenter"}
							style={{ marginBottom: 16 }}
						>
							<div className="colStart" style={{ paddingTop: 0 }}>
								<input
									className="inputStyling"
									placeholder="Instagram"
									style={{
										width: windowSize.innerWidth > 400 ? 410 : 320,
									}}
									onChange={(e) => {
										setInstagram(e.target.value);
									}}
									value={instagram}
								/>
								<span
									className="contactText"
									style={{ marginBottom: windowSize.innerWidth > 400 ? 0 : 16 }}
								>
									(We’ll contact you for friends and guests to join the podcast)
								</span>
							</div>
							<input
								className="inputStyling"
								placeholder="Friend 1 (Optional)"
								style={{
									width: windowSize.innerWidth > 400 ? 410 : 320,
								}}
								onChange={(e) => {
									setfriendOne(e.target.value);
								}}
								value={friendOne}
							/>
						</div>
						<div
							className={windowSize.innerWidth > 400 ? "rowing" : "colCenter"}
							style={{
								marginBottom: windowSize.innerWidth > 400 ? 24 : 16,
								marginTop: windowSize.innerWidth > 400 ? 32 : 16,
							}}
						>
							<input
								className="inputStyling"
								placeholder="Friend 2 (Optional)"
								style={{
									marginBottom: windowSize.innerWidth > 400 ? 0 : 16,
									width: windowSize.innerWidth > 400 ? 410 : 320,
								}}
								onChange={(e) => {
									setfriendTwo(e.target.value);
								}}
								value={friendTwo}
							/>
							<input
								className="inputStyling"
								placeholder="Friend 3 (Optional)"
								style={{
									width: windowSize.innerWidth > 400 ? 410 : 320,
								}}
								onChange={(e) => {
									setfriendThree(e.target.value);
								}}
								value={friendThree}
							/>
						</div>
						<div
							className={windowSize.innerWidth > 400 ? "rowing" : "colCenter"}
							style={{ marginBottom: 24 }}
						>
							<input
								className="inputStyling"
								placeholder="Phone Number (Optional)"
								style={{
									width: windowSize.innerWidth > 400 ? 410 : 320,
								}}
								onChange={(e) => {
									setPhonenumber(e.target.value);
								}}
								value={phoneNumber}
							/>
						</div>
						<div
							className={windowSize.innerWidth > 400 ? "colStart" : "centerMe"}
						>
							<span className="submitBtn" onClick={CheckAndSubmit}>
								Submit
							</span>
						</div>
					</div>
				</div>
				{/* </div> */}

				<img src="/sand.png" style={{ width: "100%" }} />
			</div>
			<div
				className={
					windowSize.innerWidth > 400
						? "howItWorks rowing"
						: "howItWorks colCenter"
				}
				style={{
					padding:
						windowSize.innerWidth > 400
							? "240px 80px 135px 80px"
							: "80px 24px 80px 24px",
				}}
			>
				<div
					className="colStart"
					style={{ width: windowSize.innerWidth > 400 ? 628 : 345 }}
				>
					<span className="podcasTitle">How it Works</span>
					<span className="worksText" style={{ paddingTop: 40 }}>
						Each Model of the Month winner is invited on to the podcast series
						with their friends and guests. A website link to their favorite
						charity or Gofundme page is displayed at the bottom of each podcast
						episode, so real-time donations can be generated directly for that
						charity. As the podcast series is released on YouTube, Instagram
						reels, TikTok, Onlyfans and other platforms, both the model and
						their charity will gain international exposure and active donations.
					</span>
				</div>
				<img
					src="/grf.png"
					alt="howitworks"
					style={{
						width: windowSize.innerWidth > 400 ? 520 : 345,
						height: windowSize.innerWidth > 400 ? 520 : 345,
						marginTop: windowSize.innerWidth > 400 ? 0 : 31,
					}}
				/>
			</div>
			<div
				className="winningFrame"
				style={{
					backgroundImage: "url(/winningDiv.png)",
					backgroundColor: "#e4ded5",
				}}
			>
				<span
					className="podcasTitle"
					style={{
						padding:
							windowSize.innerWidth > 400
								? "120px 0px 80px 80px"
								: "40px 24px 16px 24px",
					}}
				>
					What do you win
				</span>
				<div
					className={windowSize.innerWidth > 400 ? "rowing" : "colCenter"}
					style={{
						paddingLeft: windowSize.innerWidth > 400 ? 80 : 24,
						paddingRight: windowSize.innerWidth > 400 ? 80 : 24,
					}}
				>
					<div
						className="colFlexStart"
						style={{
							paddingRight: windowSize.innerWidth > 400 ? 25 : 0,
							paddingBottom: windowSize.innerWidth > 400 ? 0 : 40,
						}}
					>
						<div className="rowing">
							<span className="numbrText">01</span>
							<span className="headingPara">Digital Cover</span>
						</div>
						<span
							className="descriptionwinning"
							style={{
								paddingTop: windowSize.innerWidth > 400 ? 33 : 20,
							}}
						>
							A digital cover of model of the month with your favorite picture
							of that month.
						</span>
					</div>
					<div
						className="colFlexStart"
						style={{
							paddingRight: windowSize.innerWidth > 400 ? 25 : 0,
							paddingBottom: windowSize.innerWidth > 400 ? 0 : 40,
						}}
					>
						<div className="rowing" style={{ paddingBottom: 0 }}>
							<span className="numbrText">02</span>
							<span className="headingPara">Podcasts</span>
						</div>
						<span
							className="descriptionwinning"
							style={{
								paddingTop: windowSize.innerWidth > 400 ? 33 : 20,
							}}
						>
							A podcast episode with your friends and guests and/or charity
							contacts.
						</span>
					</div>
					<div
						className="colFlexStart"
						style={{
							paddingRight: windowSize.innerWidth > 400 ? 25 : 0,
							paddingBottom: windowSize.innerWidth > 400 ? 0 : 40,
						}}
					>
						<div className="rowing">
							<span className="numbrText">03</span>
							<span className="headingPara">Entertainment</span>
						</div>
						<span
							className="descriptionwinning"
							style={{
								paddingTop: windowSize.innerWidth > 400 ? 33 : 20,
							}}
						>
							International exposure to film, tv and entertainment events and
							bookings.
						</span>
					</div>
					<div
						className="colFlexStart"
						style={{
							paddingRight: windowSize.innerWidth > 400 ? 25 : 0,
							paddingBottom: windowSize.innerWidth > 400 ? 0 : 40,
						}}
					>
						<div className="rowing">
							<span className="numbrText">04</span>
							<span className="headingPara">Explore</span>
						</div>
						<span
							className="descriptionwinning"
							style={{
								paddingTop: windowSize.innerWidth > 400 ? 33 : 20,
							}}
						>
							Embark on a journey to the undiscovered travel destinations of the
							future.
						</span>
					</div>
				</div>
				{windowSize.innerWidth > 400 && (
					<div style={{ padding: "64px 0px 64px 80px" }}>
						<div className="line" />
					</div>
				)}
				<div
					className={windowSize.innerWidth > 400 ? "rowing" : "colCenter"}
					style={{
						paddingLeft: windowSize.innerWidth > 400 ? 80 : 24,
						paddingRight: windowSize.innerWidth > 400 ? 80 : 24,
					}}
				>
					<div
						className="colFlexStart"
						style={{
							paddingRight: windowSize.innerWidth > 400 ? 25 : 0,
							paddingBottom: windowSize.innerWidth > 400 ? 0 : 40,
						}}
					>
						<div className="rowing">
							<span className="numbrText">05</span>
							<span className="headingPara">Monetize</span>
						</div>
						<span
							className="descriptionwinning"
							style={{
								paddingTop: windowSize.innerWidth > 400 ? 33 : 20,
							}}
						>
							Harness the power of generating funds to support your cherished
							charities.
						</span>
					</div>
					<div
						className="colFlexStart"
						style={{
							paddingRight: windowSize.innerWidth > 400 ? 25 : 0,
							paddingBottom: windowSize.innerWidth > 400 ? 0 : 40,
						}}
					>
						<div className="rowing" style={{ paddingBottom: 0 }}>
							<span className="numbrText">06</span>
							<span className="headingPara">Platform</span>
						</div>
						<span
							className="descriptionwinning"
							style={{
								paddingTop: windowSize.innerWidth > 400 ? 33 : 20,
							}}
						>
							A platform to talk about your favourite topics with your friend
							and guests.{" "}
						</span>
					</div>
					<div
						className="colFlexStart"
						style={{
							paddingRight: windowSize.innerWidth > 400 ? 25 : 0,
							paddingBottom: windowSize.innerWidth > 400 ? 0 : 40,
						}}
					>
						<div className="rowing">
							<span className="numbrText">07</span>
							<span className="headingPara">Media</span>
						</div>
						<span
							className="descriptionwinning"
							style={{
								paddingTop: windowSize.innerWidth > 400 ? 33 : 20,
							}}
						>
							Featured in digital magazine, calendar, and media posts as the
							monthly model winner.
						</span>
					</div>
					<div
						className="colFlexStart"
						style={{
							paddingRight: windowSize.innerWidth > 400 ? 25 : 0,
							paddingBottom: windowSize.innerWidth > 400 ? 0 : 40,
						}}
					>
						<div className="rowing">
							<span className="numbrText">08</span>
							<span className="headingPara">Join</span>
						</div>
						<span
							className="descriptionwinning"
							style={{
								paddingTop: windowSize.innerWidth > 400 ? 33 : 20,
							}}
						>
							Inspiring movement and a community dedicated to good causes and
							charity.
						</span>
					</div>
				</div>
			</div>
			<div style={{ backgroundColor: "#f3f0ed" }}>
				{windowSize.innerWidth > 400 ? (
					<div
						className="cmBFrame"
						style={{
							backgroundImage: "url(/ngn.png)",
							backgroundColor: "#f3f0ed",
							height: 444,
						}}
					/>
				) : (
					<div
						className="cmBFrame"
						style={{
							backgroundImage: "url(/nava.png)",
							backgroundColor: "#f3f0ed",
							height: 140,
						}}
					/>
				)}
				{windowSize.innerWidth <= 400 && (
					<div style={{ paddingLeft: 24, paddingRight: 24 }}>
						<span
							className="mobileChoosenHeading"
							style={{
								backgroundColor: "#f3f0ed",
								width: 345,
							}}
						>
							How is the model of the month chosen?
						</span>
					</div>
				)}
				<div
					className="newFFrame"
					style={{
						backgroundImage: "url(/vdns.png)",
						height: windowSize.innerWidth <= 400 ? 200 : 731,
						marginTop: windowSize.innerWidth <= 400 ? 46 : 0,
						backgroundColor: "#f3f0ed",

						// marginTop: 16,
					}}
				>
					{windowSize.innerWidth > 400 && (
						<span className="chosendescription">
							Each month a small panel of fashion, entertainment and social
							media experts will decide on who will be awarded Model of the
							Month.
							<br />
							<br />A range of factors are included for winners each month from
							their fashion work, press, engagement, runway shows or video &
							photograph content & magazine covers that month. <br />
							<br />
							We also consider their charity work, online personality and other
							factors like popularity, comments, reach, interests and their
							positive role in society. <br />
							<br />
							International red carpet appearances, travel, event bookings and
							recent appearances in press, media, fashion, Film, TV and all
							other media & entertainment will also be taken into consideration
							for all Model of the Month winners.
						</span>
					)}
				</div>
				<div style={{ paddingLeft: 12, paddingRight: 12 }}>
					{windowSize.innerWidth <= 400 && (
						<div
							style={{
								width: 310,
								background: "rgba(255, 255, 255, 0.9)",
								borderRadius: 24,
								padding: 24,
								textAlign: "center",
								marginBottom: 100,
								marginTop: -60,
							}}
						>
							<span className="chosendescriptionMobile">
								Each month a small panel of fashion, entertainment and social
								media experts will decide on who will be awarded Model of the
								Month.
								<br />
								<br />A range of factors are included for winners each month
								from their fashion work, press, engagement, runway shows or
								video & photograph content & magazine covers that month. <br />
								<br />
								We also consider their charity work, online personality and
								other factors like popularity, comments, reach, interests and
								their positive role in society. <br />
								<br />
								International red carpet appearances, travel, event bookings and
								recent appearances in press, media, fashion, Film, TV and all
								other media & entertainment will also be taken into
								consideration for all Model of the Month winners.
							</span>
						</div>
					)}
				</div>
				{windowSize.innerWidth <= 400 ? (
					<img
						src="/bottomPlantsMob.png"
						style={{
							width: "100%",
							height: 326,
							backgroundColor: "#f3f0ed",
							// marginBottom: -5,
							objectFit: "contain",
						}}
					/>
				) : (
					<img
						src="/recentlyImg.png"
						style={{
							width: "100%",
							height: 431,
							backgroundColor: "#f3f0ed",
							marginBottom: -5,
						}}
					/>
				)}
			</div>
			<div style={{ backgroundColor: "#f3f0ed" }}>
				{windowSize.innerWidth > 400 && (
					<div style={{ padding: "60px 80px 60px 80px" }}>
						<span className="bototmMostHeading" style={{ fontSize: 56 }}>
							Our partners
						</span>
					</div>
				)}
				{windowSize.innerWidth <= 400 && (
					<div className="colStart" style={{ padding: "80px 24px 80px 24px" }}>
						<span className="bototmMostHeading" style={{ textAlign: "center" }}>
							Our partners
						</span>
					</div>
				)}
				<div
					className="centerMe"
					onClick={() =>
						window.open("https://www.instagram.com/modelofthemonthtv/")
					}
				>
					<img
						src="/insta.png"
						alt="instaImg"
						style={{
							width: windowSize.innerWidth <= 400 ? 338 : 397,
							height: windowSize.innerWidth <= 400 ? 371 : 437,
							marginTop: windowSize.innerWidth <= 400 ? 0 : 40,
							marginBottom: 32,
						}}
					/>
				</div>
				<div className="centerMe" style={{ marginBottom: 120 }}>
					<div
						className="subscriptionDiv"
						style={{ width: windowSize.innerWidth > 400 ? 397 : 280 }}
					>
						<span className="subscribeTxt">Subscribe to newsletter</span>
						<div className="emailInput">
							<input
								placeholder="Email address"
								className="emailInputStyle"
								style={{
									width: windowSize.innerWidth > 400 ? 250 : 200,
								}}
								onChange={(e) => {
									setSubscriptionEmail(e.target.value);
								}}
								value={subsrciptionEmail}
							/>
							<span className="submitttText" onClick={checkAndSubscribe}>
								submit
							</span>
						</div>
					</div>
				</div>
				<div className="colCenter">
					<span
						className="savOcean"
						style={{
							fontSize: windowSize.innerWidth > 400 ? 56 : 32,
							textAlign: windowSize.innerWidth > 400 ? "left" : "center",
							lineHeight: windowSize.innerWidth > 400 ? "67px" : "38px",
						}}
					>
						HELP SAVE OUR OCEANS
					</span>
					<span
						className="joinToday"
						style={{
							fontSize: windowSize.innerWidth > 400 ? 18 : 20,
							textAlign: windowSize.innerWidth > 400 ? "left" : "center",
							lineHeight: "32px",
							marginTop: windowSize.innerWidth > 400 ? 43 : 16,
							marginBottom: windowSize.innerWidth > 400 ? 150 : 82,
						}}
					>
						Join the Podcast today!
					</span>
					{windowSize.innerWidth <= 400 && (
						<span className="pleaseSubmit">Please submit at</span>
					)}
					{windowSize.innerWidth <= 400 && (
						<span
							className="submission"
							style={{ marginTop: 15, marginBottom: 15 }}
						>
							submissions@modelofthemonth.org{" "}
						</span>
					)}
					{windowSize.innerWidth <= 400 && (
						<span
							className="submission"
							style={{ marginTop: 0, marginBottom: 50 }}
						>
							Podcast@modelofthemonth.org
						</span>
					)}
				</div>
				<img src="/fishyy.png" style={{ width: "100%", height: "100%" }} />
			</div>
		</div>
	);
}

export default App;
