// import moment from "moment";
import { initializeApp } from "firebase/app";
import {
	GoogleAuthProvider,
	getAuth,
	signInWithPopup,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	signOut,
} from "firebase/auth";
import {
	getFirestore,
	query,
	getDocs,
	collection,
	where,
	addDoc,
	updateDoc,
	doc,
	deleteDoc,
} from "firebase/firestore";

import {
	getStorage,
	uploadBytesResumable,
	getDownloadURL,
	ref,
} from "firebase/storage";
// import { v4 } from "uuid";

const firebaseConfig = {
	apiKey: "AIzaSyAoDcQ5Q3sk4XEiOjMsDpM8ZwdFKmlxMnA",
	authDomain: "movieproject-e815d.firebaseapp.com",
	projectId: "movieproject-e815d",
	storageBucket: "movieproject-e815d.appspot.com",
	messagingSenderId: "523084287765",
	appId: "1:523084287765:web:f69f5226da5456aa1be31c",
	measurementId: "G-FWQZ7M7C90",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const storage = getStorage(app);

const signInWithGoogle = async () => {
	try {
		const res = await signInWithPopup(auth, googleProvider);
		const user = res.user;
		const q = query(collection(db, "users"), where("uid", "==", user.uid));
		const docs = await getDocs(q);
		if (docs.docs.length === 0) {
			await addDoc(collection(db, "users"), {
				uid: user.uid,
				name: user.displayName,
				authProvider: "google",
				email: user.email,
			});
		}
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};
const logInWithEmailAndPassword = async (email, password) => {
	let user = null;
	try {
		const resp = await signInWithEmailAndPassword(auth, email, password);
		// console.log("response is", resp);
		user = resp.user;
		return user;
	} catch (err) {
		console.error(err);
		// alert(err.message);
		return user;
	}
};
const registerWithEmailAndPassword = async (
	name,
	email,
	password,
	type = "admin"
) => {
	try {
		const res = await createUserWithEmailAndPassword(auth, email, password);
		const user = res.user;
		await addDoc(collection(db, "users"), {
			uid: user.uid,
			name,
			authProvider: "local",
			email,
			type,
		});
		return res.user;
	} catch (err) {
		console.error(err);
		//alert(err.message);
	}
};

//-----------Productss-----------------------
const addProduct = async (userDetails) => {
	let {
		name = "",
		friendOne = "",
		friendTwo = "",
		friendThree = "",
		email = "",
		phoneNumber = "",
		instagram = "",
	} = userDetails;

	try {
		await addDoc(collection(db, "user"), {
			name,
			friendOne,
			friendTwo,
			friendThree,
			email,
			phoneNumber,
			instagram,
		});
		return true;
	} catch (err) {
		console.error(err);
		return false;
	}
};
//-----------subscriptionemail-----------------------
const addSubscription = async (email = "") => {
	try {
		await addDoc(collection(db, "subscription"), {
			email,
		});
		return true;
	} catch (err) {
		console.error(err);
		return false;
	}
};

//-------------------------------------------
const sendPasswordReset = async (email) => {
	try {
		await sendPasswordResetEmail(auth, email);
		alert("Password reset link sent!");
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};
const logout = () => {
	signOut(auth);
};
export {
	auth,
	db,
	signInWithGoogle,
	logInWithEmailAndPassword,
	registerWithEmailAndPassword,
	sendPasswordReset,
	logout,
	storage,
	addProduct,
	addSubscription,
};
